import React, { useMemo } from 'react';
import BlogCard, { getMonthsAgo } from './BlogCard/BlogCard';
import Pagination from './Pagination';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import './BlogListPage.css';

const BlogListPage = ({ blogs, currentPage, handlePageChange, totalPages }) => {
  const { featuredBlog, gridBlogs } = useMemo(() => {
    if (!blogs || blogs.length === 0) {
      return { featuredBlog: null, gridBlogs: [] };
    }

    return {
      featuredBlog: blogs[0],
      gridBlogs: blogs.slice(1)
    };
  }, [blogs]);

  if (!blogs || blogs.length === 0) {
    return <div>No blogs found</div>;
  }

  return (
    <div className="blog-list-container">
      <div className="blog-list-content">
        <div className="text-center mb-4 sm:mb-10 pt-10 sm:pt-20">
          <h1 className="blog-list-title">Our Blogs</h1>
        </div>

        {/* Featured Blog Banner */}
        {featuredBlog && (
          <div className="w-full flex justify-center mb-12 sm:mb-16 px-4 sm:px-0">
            <Link 
              to={`/blog/${featuredBlog.postId}`} 
              className="featured-blog-link block w-full sm:w-[95%]"
            >
              <div className="featured-blog-container">
                <div className="featured-blog-content">
                  <h2 className="featured-blog-title">{featuredBlog.title}</h2>
                  <div className="featured-blog-meta">
                    <p className="featured-blog-date">{getMonthsAgo(featuredBlog.createdAt)}</p>
                    <span className="featured-blog-read-more">Read More →</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        )}

        <div className="container mx-auto px-4 sm:px-6">
          <h2 className="latest-blogs-title">Latest Blogs</h2>

          {/* Grid Blogs Section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
            {gridBlogs.map(blog => (
              <BlogCard
                key={blog.postId}
                postId={blog.postId}
                title={blog.title}
                date={blog.createdAt}
                image={blog?.images && blog?.images[0] ? blog?.images[0] : '/path-to-default-image.jpg'}
              />
            ))}
          </div>

          <div className="mt-8 sm:mt-12 mb-16">
            <Pagination 
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default BlogListPage;
