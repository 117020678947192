import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import axios from "axios";
import './BlogDetailPage.css';
import BlogListPage from "./BlogListPage";
import BlogDetailPage from "./BlogDetailPage";
import ComingSoon from "../../components/ComingSoon";

const BlogRoutes = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${apiUrl}/api/blogs?page=${currentPage - 1}`);

        const { posts, total } = response.data;
        if (posts && posts.length > 0) {
          setBlogs(posts);
          setTotalPosts(total);
        }
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch blogs");
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [currentPage, apiUrl]);
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(totalPosts / 5); // Assuming 5 posts per page

  if (loading && blogs.length === 0) {
    return <ComingSoon />;
  }

  if (error || blogs.length === 0) {
    return <ComingSoon />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <BlogListPage
            blogs={blogs}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages}
          />
        }
      />
      <Route path="/blog/:postId" element={<BlogDetailPage />} />
    </Routes>
  );
};

export default BlogRoutes;
