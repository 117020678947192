import React, { useState, useEffect, useRef, useMemo } from "react";
import { Container, Form, Button, Alert, Row, Col } from "react-bootstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import CountryCodeInput from "../../countryInput";
import "./ForgotPassword.css";

const ForgotPassword = () => {
  const apiUrl = useMemo(() => process.env.REACT_APP_API_BASE_URL, []);
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    countryCode: "+91" // Default country code
  });
  const [contactMethod, setContactMethod] = useState("email");
  const [contactMethodMessage, setContactMethodMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [userId, setUserId] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [timer, setTimer] = useState(300);
  const [showResendButton, setShowResendButton] = useState(false);
  const [timerStyle, setTimerStyle] = useState({});
  const [resendAttempts, setResendAttempts] = useState(0);
  const navigate = useNavigate();
  const otpInputs = useRef([]);

  useEffect(() => {
    if (step === 2) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(countdown);
            setShowResendButton(true);
            return 0;
          }
          if (prevTimer <= 20) {
            setTimerStyle(prevTimer <= 10 ? 'red-timer blinking-timer' : 'red-timer');
          } else {
            setTimerStyle({});
          }
          return prevTimer - 1;
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [step]);

  useEffect(() => {
    if (contactMethodMessage) {
      setShowMessage(true);
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [contactMethodMessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    setError("");
  };

  const handleSendOTP = async (e) => {
    e.preventDefault();
    try {
      const payload = contactMethod === "email" 
        ? { emailOrPhone: formData.email }
        : { emailOrPhone: `${formData.countryCode}${formData.phone}` };
      
      const response = await axios.post(`${apiUrl}/api/forgot-password`, payload);
      setUserId(response.data.userId);
      setMessage(response.data.message);
      setStep(2);
      setTimer(300);
      setShowResendButton(false);
    } catch (error) {
      setError(error.response?.data?.message || "An error occurred. Please try again.");
    }
  };

  const handleResendOTP = async () => {
    if (showResendButton && resendAttempts < 3) {
      try {
        const payload = contactMethod === "email"
          ? { emailOrPhone: formData.email }
          : { emailOrPhone: `${formData.countryCode}${formData.phone}` };

        const response = await axios.post(`${apiUrl}/api/forgot-password`, payload);
        setMessage("New OTP sent successfully!");
        setTimer(300);
        setShowResendButton(false);
        setResendAttempts(prevAttempts => prevAttempts + 1);
      } catch (error) {
        setError("Failed to resend OTP. Please try again.");
      }
    }
  };

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value.length === 1 && index < 5) {
        otpInputs.current[index + 1].focus();
      }
    } else if (value === '' && index > 0) {
      // Handle backspace
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
      otpInputs.current[index - 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      // Move to the previous input when backspace is pressed on an empty input
      otpInputs.current[index - 1].focus();
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      const response = await axios.post(`${apiUrl}/api/reset-password`, {
        userId,
        otp: otp.join(""),
        newPassword
      });
      setMessage(response.data.message);
      setTimeout(() => navigate("/login"), 3000);
    } catch (error) {
      setError(error.response?.data?.message || "An error occurred. Please try again.");
    }
  };

  return (
    <Container fluid className="forgot-password-container">
      <Row className="justify-content-center w-100">
        <Col xs={12} sm={10} md={8} lg={6} xl={4}>
          <Form onSubmit={step === 1 ? handleSendOTP : handleResetPassword} className="forgot-password-form">
            <h2 className="forgot-password-heading">Forgot Password</h2>
            {step === 1 && (
              <>
                <Form.Group controlId="formContactMethod" className="w-100 mb-3">
                  <div className="d-flex justify-content-center">
                    <Form.Check
                      type="radio"
                      label="Email"
                      name="contactMethod"
                      id="emailMethod"
                      checked={contactMethod === "email"}
                      onChange={() => {
                        setContactMethod("email");
                        setContactMethodMessage("Your registered phone number associated with this email will receive an OTP from Verbalyze.");
                      }}
                      className="me-3"
                    />
                    <Form.Check
                      type="radio"
                      label="Phone"
                      name="contactMethod"
                      id="phoneMethod"
                      checked={contactMethod === "phone"}
                      onChange={() => {
                        setContactMethod("phone");
                        setContactMethodMessage("Please ensure you are entering the registered phone number. You will receive an OTP from Verbalyze.");
                      }}
                    />
                  </div>
                </Form.Group>

                {contactMethod === "email" ? (
                  <Form.Group controlId="formEmail" className="w-100 mb-3">
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                ) : (
                  <CountryCodeInput
                    formData={formData}
                    handleChange={handleChange}
                    errors={{}}
                  />
                )}

                <Button variant="primary" type="submit" className="forgot-password-submit-btn">
                  Send OTP
                </Button>

                <div className={showMessage ? 'fade-in' : 'fade-out'}>
                  {contactMethodMessage && (
                    <Alert 
                      variant="info" 
                      className="w-100 mb-3"
                    >
                      {contactMethodMessage}
                    </Alert>
                  )}
                </div>
              </>
            )}

            {error && <Alert variant="danger" className="w-100 mb-3">{error}</Alert>}
            {message && <Alert variant="info" className="w-100 mb-3">{message}</Alert>}

            {step === 2 && (
              <>
                <Form.Group controlId="formOtp" className="w-100 mb-3">
                  <Form.Label>Enter OTP</Form.Label>
                  <div className="d-flex justify-content-between">
                    {otp.map((digit, index) => (
                      <Form.Control
                        key={index}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleOtpChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (otpInputs.current[index] = el)}
                        className="text-center mx-1 otp-input"
                      />
                    ))}
                  </div>
                </Form.Group>
                <div className="w-100 mb-3 d-flex justify-content-between align-items-center">
                  <span style={timerStyle}>{Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, '0')}</span>
                  {resendAttempts < 3 ? (
                    <Button 
                      variant="link" 
                      onClick={handleResendOTP} 
                      disabled={!showResendButton}
                      className={`resend-button ${!showResendButton ? 'disabled' : ''}`}
                    >
                      Resend OTP
                    </Button>
                  ) : (
                    <span>Please try again later</span>
                  )}
                </div>
                <Form.Group controlId="formNewPassword" className="w-100 mb-3">
                  <Form.Control
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formConfirmPassword" className="w-100 mb-3">
                  <Form.Control
                    type="password"
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="forgot-password-submit-btn">
                  Reset Password
                </Button>

                <div className={showMessage ? 'fade-in' : 'fade-out'}>
                  {message && (
                    <Alert 
                      variant="info" 
                      className="w-100 mb-3"
                    >
                      {message}
                    </Alert>
                  )}
                </div>
              </>
            )}
            
            <p className="forgot-password-login-link">
              Remember your password? <Link to="/login"><b>Log In</b></Link>
            </p>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
