import React, { useState, useEffect } from "react";
import { Container, Form, Button, Alert, InputGroup } from "react-bootstrap";
import axios from "axios";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const LoginForm = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there's a stored email in localStorage
    const storedEmail = localStorage.getItem('rememberedEmail');
    if (storedEmail) {
      setFormData(prevState => ({ ...prevState, email: storedEmail }));
      setRememberMe(true);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setRememberMe(checked);
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
    setLoginError(""); // Clear login error when user types
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear specific field error
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.password) newErrors.password = "Password is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    try {
      console.log("Attempting to log in...");
      const response = await axios.post(`${apiUrl}/api/login`, { ...formData, rememberMe }, {
        withCredentials: true // This is important for sending and receiving cookies
      });
      console.log("Login response:", response);
      if (response.status === 200 && response.data) {
        if (rememberMe) {
          localStorage.setItem('rememberedEmail', formData.email);
        } else {
          localStorage.removeItem('rememberedEmail');
        }
        console.log("Login successful. Redirecting...");
        // Encode the entire response data and pass it in the URL
        const encodedResponse = encodeURIComponent(JSON.stringify(response.data));
        const redirectUrl = `https://aitalker-product.pages.dev/onboarding/profile?responseData=${encodedResponse}`;
        console.log("Redirect URL:", redirectUrl);
        window.location.href = redirectUrl;
      } else {
        console.error("Unexpected response:", response);
        setLoginError("Unexpected response from server. Please try again.");
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      if (error.response) {
        console.error("Error response:", error.response);
        if (error.response.status === 401) {
          setLoginError("Invalid email or password. Please try again.");
        } else {
          setLoginError(`Server error: ${error.response.data.message || 'Unknown error'}`);
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
        setLoginError("No response from server. Please check your internet connection.");
      } else {
        console.error("Error", error.message);
        setLoginError("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      <h2 className="login-heading">Welcome Back!</h2>
      <p className="login-subheading">
        Please Enter Your Details 
      </p>
      {loginError && <Alert variant="danger" className="w-100 mb-3">{loginError}</Alert>}
      <Form.Group controlId="formBasicEmail" className="login-input">
        <Form.Control type="email" name="email" placeholder="Enter email" value={formData.email} onChange={handleChange} isInvalid={!!errors.email} />
        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formBasicPassword" className="login-input">
        <InputGroup>
          <Form.Control
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            isInvalid={!!errors.password}
          />
          <div className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </div>
          <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group controlId="formBasicCheckbox" className="login-remember-forgot d-flex justify-content-between align-items-center w-100 mb-3">
        <Form.Check 
          type="checkbox" 
          label="Remember me" 
          className="text-sm" 
          checked={rememberMe}
          onChange={handleChange}
        />
        <Form.Text className="text-muted">
          <Link to="/forgot-password" className="forgot">Forgot Password?</Link>
        </Form.Text>
      </Form.Group>
      <Button variant="primary" type="submit" className="login-submit-btn mb-3">
        Log In
      </Button>
      <p className="login-signup-link">
        Don't have an account? <Link to="/Signup"><b>Sign Up</b></Link>
      </p>
    </Form>
  );
};

const Login = () => {
  return (
    <Container className="login-container">
      <LoginForm />
    </Container>
  );
};

export default Login;
