import "./App.css";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import About from "./pages/aboutus/about";
import Home from "./pages/Home/Home";
import PrivacyPolicy from "./pages/policy/privacyPolicy";
import DataSecurityPolicy from "./pages/policy/dataSecurityPolicy";
import TermsOfService from "./pages/policy/termsOfService";
import OurServices from "./pages/services/service";
import Pricing from "./components/Pricing/Pricing";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ContactUs from "./pages/ContactUs/ContactUs";
import PartnerWithUs from "./pages/PartnerWithUs/PartnerWithUs";
import Career from "./pages/Career/Career";
import Newsroom from "./pages/Newsroom/Newsroom";
import Blog from "./pages/Blog/BlogRoutes";
import BlogDetailPage from "./pages/Blog/BlogDetailPage"
import BFSI from "./pages/Industries/BFSI";
import Healthcare from "./pages/Industries/Healthcare";
import Utilities from "./pages/Industries/Utilities";
import Retail from "./pages/Industries/Retail";
import OtherIndustries from "./pages/Industries/OtherIndustries";
import CookiePreferences from "./pages/CookiePreferences/CookiePreferences";
import Sitemap from "./pages/Sitemap/Sitemap";
import Signup from "./components/auth/signup/Signup";
import Login from "./components/auth/login/login";
import SignupTwoForm from "./components/auth/signup/signuptwo";
import SignupthreeForm from "./components/auth/signup/signupthree";
import ForgotPassword from "./components/auth/forgot/ForgotPassword";
import ScrollToTop from "./components/ScrollToTop";
import NoPage from "./components/NoPage";

const pageTitles = {
  '/': 'Home',
  '/home': 'Home',
  '/about-us': 'About Us',
  '/privacy-policy': 'Privacy Policy',
  '/data-security-policy': 'Data Security Policy',
  '/terms-of-service': 'Terms of Service',
  '/services': 'Services',
  '/pricing': 'Pricing',
  '/contact-us': 'Contact Us',
  '/partner': 'Partner With Us',
  '/careers': 'Careers',
  '/newsroom': 'Newsroom',
  '/blog': 'Blog',
  '/industry/bfsi': 'BFSI Industry',
  '/industry/healthcare': 'Healthcare Industry',
  '/industry/utilities': 'Utilities Industry',
  '/industry/retail': 'Retail Industry',
  '/industry/other': 'Other Industries',
  '/cookie-preferences': 'Cookie Preferences',
  '/sitemap': 'Sitemap',
  '/signup': 'Sign Up',
  '/login': 'Login',
  '/signuptwo': 'Sign Up - Step 2',
  '/signupthree': 'Sign Up - Step 3',
  '/forgot-password': 'Forgot Password',
};

function TitleSetter() {
  const location = useLocation();
  
  useEffect(() => {
    const title = pageTitles[location.pathname] || 'Page Not Found';
    document.title = `Verbalyze | ${title}`;
  }, [location]);

  return null;
}

function App() {
  return (
    <HelmetProvider>
      <Router>
        <TitleSetter />
        <ScrollToTop />
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/data-security-policy" element={<DataSecurityPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/services" element={<OurServices />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/partner" element={<PartnerWithUs />} />
            <Route path="/careers" element={<Career />} />
            <Route path="/newsroom" element={<Newsroom />} />
            <Route path="/blog/*" element={<Blog />} />
            <Route path="/blog/:postId" element={<BlogDetailPage />} />
            <Route path="/industry/bfsi" element={<BFSI />} />
            <Route path="/industry/healthcare" element={<Healthcare />} />
            <Route path="/industry/utilities" element={<Utilities />} />
            <Route path="/industry/retail" element={<Retail />} />
            <Route path="/industry/other" element={<OtherIndustries />} />
            <Route path="/cookie-preferences" element={<CookiePreferences />} />
            <Route path="/sitemap" element={<Sitemap />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signuptwo" element={<SignupTwoForm />} />
            <Route path="/signupthree" element={<SignupthreeForm />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="*" element={<NoPage/>} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
